import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyASeFg3CUnueklpK7vF5S9ddOeaDLhBAyI",
  authDomain: "twitterbot-6b2c3.firebaseapp.com",
  projectId: "twitterbot-6b2c3",
  storageBucket: "twitterbot-6b2c3.firebasestorage.app",
  messagingSenderId: "869736742326",
  appId: "1:869736742326:web:dfef9a36ba5095037d5517"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);