import { useState, useEffect } from 'react';
import { ExternalLink } from 'lucide-react';
import { db } from './firebase';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import './App.css';

const App = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const tokensCollection = collection(db, 'tokens');
        const tokenQuery = query(
          tokensCollection,
          orderBy('timestamp', 'desc'),
          limit(8)
        );

        const querySnapshot = await getDocs(tokenQuery);
        const tokensList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate()
        }));

        setTokens(tokensList);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching tokens:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTokens();
    const interval = setInterval(fetchTokens, 30000);
    return () => clearInterval(interval);
  }, []);

  const BackgroundAnimation = () => (
    <div className="background-animation">
      <div className="gradient-overlay"></div>
      <div className="floating-orbs">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="orb"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: `${Math.random() * 300 + 50}px`,
              height: `${Math.random() * 300 + 50}px`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${Math.random() * 10 + 10}s`
            }}
          />
        ))}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="loading-container">
        <BackgroundAnimation />
        <div className="loading-text">Loading tokens...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <BackgroundAnimation />
        <div className="error-text">Error loading tokens: {error}</div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <BackgroundAnimation />

      <div className="content-wrapper">
        <header className="header">
          <h1 className="title">PumpPad Latest Tokens</h1>
          <p className="subtitle">Click any token to view on pump.fun</p>
        </header>

        <div className="token-grid">
          {tokens.map((token, index) => (
            <div
              key={token.id}
              className="token-card"
              style={{ animationDelay: `${index * 100}ms` }}
              onClick={() => window.open(`https://pump.fun/${token.mint_address}`, '_blank')}
            >
              <div className="card-gradient-overlay"></div>

              <div className="image-container">
                <img
                  src={token.image_url}
                  alt={token.token_name}
                  className="token-image"
                />
                <div className="image-overlay"></div>
              </div>

              <div className="card-content">
                <div className="card-header">
                  <h2 className="token-name">
                    {token.token_name.replace(/_/g, ' ')}
                  </h2>
                  <ExternalLink className="external-link" />
                </div>

                <div className="card-footer">
                  <span className="token-ticker">
                    ${token.token_ticker}
                  </span>
                  <span className="view-text">
                    View details →
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {tokens.length === 0 && (
          <div className="empty-state">
            <h2>No tokens found</h2>
            <p>New tokens will appear here as they are created</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;